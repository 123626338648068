// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-recipe-js": () => import("/opt/build/repo/src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-fi-js": () => import("/opt/build/repo/src/pages/fi.js" /* webpackChunkName: "component---src-pages-fi-js" */),
  "component---src-pages-food-js": () => import("/opt/build/repo/src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-food-recipes-js": () => import("/opt/build/repo/src/pages/food/recipes.js" /* webpackChunkName: "component---src-pages-food-recipes-js" */),
  "component---src-pages-tech-js": () => import("/opt/build/repo/src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

